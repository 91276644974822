import logo from "./logo.svg";
import "./App.css";
import { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-input-range/lib/css/index.css";
import loadingVideo from "./loadingVideo.mp4";

import Toast from "react-bootstrap/Toast";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Col from "react-bootstrap/Col";
import InputRange from "react-input-range";
import numberSeparator from "number-separator";

import axios from "axios";

function App() {
  const emptyToken = {
    name: "",
    image: "",
    attributes: "",
    priceStart: 0,
    priceEnd: 0,
  };

  let emptySlider = {
    minSlider: 0,
    maxSlider: 100,
    value: {
      min: 10,
      max: 20,
    },
  };

  const [idValue, setIdValue] = useState(100);
  const [loading, setLoading] = useState(false);
  const [collectionName, setCollectionName] = useState("BAYC");
  const [loaded, setLoaded] = useState(false);
  const [token, setToken] = useState(emptyToken);
  const [error, setError] = useState("");
  const [sliderValues, setSliderValues] = useState(emptySlider);
  const [collections, setCollections] = useState([]);

  useEffect(() => {
    setLoading(true);

    axios
      .get("https://blockrating-backend.xtrafund.workers.dev/collections/")
      .then(function (response) {
        if (response.data.length > 0) {
          setCollections(response.data);
          setLoading(false);
        }
      });
  }, []);

  function changeCollection(newValue) {
    setCollectionName(newValue);
  }

  function delay(time) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }

  async function calculatePrice(collectionN, cId) {
    setLoading(true);

    
    const contractX = collections.find((el) => el.name.toLowerCase() === collectionN.toLowerCase());
    const contractAddress = contractX.contractName;

    if (cId > contractX.max || cId < contractX.min) {
      setError(
        "Valid id from " +
          numberSeparator(contractX.min) +
          " to " +
          numberSeparator(contractX.max)
      );
      setLoading(false);
      return;
    }

    await delay(2000);

    let tokenInfo = emptyToken;

    axios
      .get(
        "https://blockrating-backend.xtrafund.workers.dev/" +
          collectionN +
          "/" +
          cId
      )
      .then(function (xd) {
        let sliderSet = emptySlider;
        sliderSet.maxSlider = xd.data.collectionMaxPrice;
        sliderSet.minSlider = xd.data.collectionMinPrice;
        sliderSet.value.min = xd.data.tokenMinPrice;
        sliderSet.value.max = xd.data.tokenMaxPrice;
        setSliderValues(sliderSet);
        
        axios
          .get(
            "https://api.opensea.io/api/v1/asset/" +
              contractAddress +
              "/" +
              cId +
              "/?include_orders=false"
          )
          .then(function (response) {
            tokenInfo.image = response.data.image_url;
            tokenInfo.name =
              response.data.collection.name + " #" + response.data.token_id;
            if (response.data.name) {
              tokenInfo.name = response.data.name;
            }
            tokenInfo.attributes = response.data.traits;

            setToken(tokenInfo);
            setLoading(false);
            setLoaded(true);
            setError("");
          })
          .catch(function (error) {
            setLoading(false);
            setLoaded(false);
            setError(error)
          })
          .then(function () {
            // always executed
          });
      });
  }

  function setMainPage() {
    setLoaded(false);
    setLoading(false);
  }

  return (
    <Row className="  d-flex aligns-items-center" style={{ height: "100%" }}>
      <div
        className={
          loading && !loaded
            ? "d-flex aligns-items-center justify-content-center loader"
            : "d-none"
        }
      >
        <video className="videoTag" width={500} autoPlay loop muted>
          <source src={loadingVideo} type="video/mp4" />
        </video>
      </div>
      <Container
        className={
          loading || loaded ? "d-none" : "d-flex justify-content-center loader"
        }
        style={{ alignItems: "center" }}
      >
        <Container
          className="p-5 mb-4 bg-light rounded-3"
          // style={{ marginTop: "100px" }}
        >
          <img src="logo_BR-1.svg" height={30}></img>
          <h1 style={{ paddingTop: 20 }} className="header">
            Welcome To Blockrating
          </h1>
          <br></br>
          <h5 className="subtitle">
            Select collection and enter token id to calculate the price
          </h5>
          <br></br>
          <InputGroup size="lg">
            <Form.Select
              aria-label="Default select example"
              onChange={(e) => changeCollection(e.target.value)}
            >
              {collections.map((e) => (
                <option value={e.name}>{e.fullName}</option>
              ))}
            </Form.Select>
            <Form.Control
              aria-label="TokenId:"
              aria-describedby="inputGroup-sizing-sm"
              onChange={(e) => setIdValue(e.target.value)}
              value={idValue}
            />
            <Button
              type="submit"
              onClick={() => calculatePrice(collectionName, idValue)}
            >
              Calculate
            </Button>{" "}
          </InputGroup>
          <div style={{ paddingTop: 10, color: "red" }}>{error}</div>
        </Container>
      </Container>
      <Container
        className={
          !loaded
            ? "d-none"
            : "d-flex aligns-items-center justify-content-center loader"
        }
        style={{ alignItems: "center" }}
      >
        <Container
          className="p-5 mb-4 bg-light rounded-3"
          // style={{ marginTop: "200px" }}
        >
          <div
            className="d-flex aligns-items-center"
            style={{ justifyContent: "space-between" }}
          >
            <div>
              <img src="logo_BR-1.svg" height={30}></img>
            </div>
            <div>
              <Button variant="outline-primary" onClick={setMainPage}>
                Back
              </Button>{" "}
            </div>
          </div>

          <Row
            style={{ marginTop: 40 }}
            style={{
              display: "flex",
              justifyContent: "center",
              paddingTop: 20,
            }}
          >
            <Col
              md={12}
              lg={6}
              style={{ justifyContent: "center", display: "flex" }}
            >
              <img src={token.image} height={350}></img>
            </Col>
            <Col md={12} lg={6}>
              <h2 className="header" style={{ paddingTop: "20px" }}>
                {token.name}
              </h2>
              <div
                style={{
                  marginTop: 30,
                  marginBottom: 40,
                  paddingLeft: 10,
                  paddingRight: 10,
                }}
              >
                <InputRange
                  formatLabel={(value) => `${numberSeparator(value)} USDT`}
                  maxValue={sliderValues.maxSlider}
                  minValue={sliderValues.minSlider}
                  value={sliderValues.value}
                />
              </div>

              <div>
                <b>Price Range From: </b>
                <span>{numberSeparator(sliderValues.value.min)} USDT</span>
                <b> To: </b>
                <span>{numberSeparator(sliderValues.value.max)} USDT</span>
              </div>
              <h5 style={{ paddingTop: 10 }}>Attributes:</h5>
              {token.attributes &&
                token.attributes.map((el) => (
                  <div>
                    <b>{el.trait_type} : </b>
                    <span>{el.value}</span>
                  </div>
                ))}
            </Col>
          </Row>
        </Container>
      </Container>
    </Row>
  );
}

export default App;
